import { z } from 'zod';
import { SortFieldSchema } from './general';
import { BrandLogosSchema, BrandStyleSchema, ContainerStylesSchema } from './user-settings';
import { ContactSchema } from './contact';
import { PixelPopupWidgetSchema } from './pixel';

export const SignupFormStoreStatsSchema = z.object({
	storeID: z.number(),
	views: z.number(),
	signups: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});

export const SignupFormViewStatsSchema = z.object({
	stores: z.array(SignupFormStoreStatsSchema),
	totalViews: z.number(),
	totalSignups: z.number(),

	conversionPercent: z.number(),
	firstView: z.date(),
	lastView: z.date(),
});

export const SignupFormFieldTypeSchema = z.enum([
	'text-short',
	'text-long',
	'radio-button',
	'single-select-dropdown',
	'multi-select-dropdown',
	'checkbox',
	'toggle',
	'number',
	'date',
	'favoriteStore',
	'email',
	'phone',
	'address',
	'signature',
	'captcha',
	'customerType',
	'termsAndConditions',
]);

export const SignupFormValidatorSchema = z.object({
	comparator: z.string().optional(),
	value: z.any().optional(),
});

// Schemas
export const SignupFormFieldSchema = z.object({
	id: z.string().optional(),
	type: SignupFormFieldTypeSchema.optional(),
	internalName: z.string().optional(),
	label: z.string().optional(),
	description: z.string().optional(),
	property: z.string().optional(),
	placeholder: z.string().optional(),
	options: z.array(z.any()).optional(),
	validator: SignupFormValidatorSchema.optional(),
	priority: z.number().int().optional(),
	enabled: z.boolean().optional(),
	required: z.boolean().optional(),
	isDefault: z.boolean().optional(),
	style: z.any().optional(),
	skipValidation: z.boolean().optional(),
});

export const SignupFormFieldWithFormsSchema = SignupFormFieldSchema.extend({
	forms: z.array(z.number()),
});

export const SignupThankYouActionSchema = z.object({
	showPopup: z.boolean().optional(),
	title: z.string().optional(),
	subTitle: z.string().optional(),
	redirectURL: z.string().optional(),
	popupDuration: z.number().optional(),
});

export const SignupFormBackgroundTypeSchema = z.enum(['color', 'image']);

export const SignupContainerStylesSchema = z.object({
	...ContainerStylesSchema.shape,
	backgroundType: SignupFormBackgroundTypeSchema.optional(),
});

export const SignupFormLogoOptionSchema = z.enum(['full-logo', 'avatar']);
export const SignupFormContentAndDesignSchema = z.object({
	title: z.string().optional(),
	brandName: z.string().optional(),
	subTitle: z.string().optional(),
	description: z.string().optional(),
	consentText: z.string().optional(),
	buttonText: z.string().optional(),
	logoOption: SignupFormLogoOptionSchema.optional(),
	enableLogo: z.boolean().optional(),
	enableCoverPhoto: z.boolean().optional(),
	enableBrandName: z.boolean().optional(),
	style: BrandStyleSchema.optional(),
	images: BrandLogosSchema.optional(),
	pageStyle: SignupContainerStylesSchema.optional(),
	thankYouAction: SignupThankYouActionSchema.optional(),
});

export const SignupFormSchema = z.object({
	id: z.number().optional(),
	userID: z.string().optional(),
	legacyID: z.string().optional(),
	name: z.string().optional(),
	signupFields: z.array(SignupFormFieldSchema).optional(),
	popupWidget: PixelPopupWidgetSchema.optional(),
	settingsMap: z.record(z.number(), SignupFormContentAndDesignSchema).optional(),
	isRAF: z.boolean().optional(),
	sendTextOptIn: z.boolean().optional(),
	sendEmailOptIn: z.boolean().optional(),
	autoTextOptIn: z.boolean().optional(),
	defaultStores: z.array(z.number()).optional(),
	version: z.number().optional(),
	isDefault: z.boolean().optional(),
	created: z.string().optional(),
	updated: z.string().optional(),
	legacyCreated: z.string().optional(),
	upgraded: z.string().optional(),
	archived: z.boolean().optional(),
});

export const SignupFormSortFieldsSchema = z.object({
	id: SortFieldSchema.optional(),
	name: SortFieldSchema.optional(),
	views: SortFieldSchema.optional(),
	submissions: SortFieldSchema.optional(),
	created: SortFieldSchema.optional(),
	updated: SortFieldSchema.optional(),
});

export const SignupContactContextSchema = z.object({
	referrer: z.string().optional(),
	formID: z.number().optional(),
	storeID: z.number().optional(),
	contact: ContactSchema.partial().optional(),
	signupFields: z.record(z.string(), z.any()).optional(),
	disableSMS: z.boolean().optional(),
	disableEmail: z.boolean().optional(),
	refreshSMSOptIn: z.boolean().optional(),
	refreshEmailOptIn: z.boolean().optional(),
});
